import React, { useState } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
// Routed pages
import Form from './form/Form';
import CardPreview from './preview/CardPreview';
import ReactGA from 'react-ga';


function VeeCard() {
    // Initialize google analytics
    ReactGA.initialize('UA-158652532-1');
    
    const [vCard, setVCard] = useState({});
    return (
        <BrowserRouter>
            <Switch>
                <Route exact path="/">
                    <Form setVCard={setVCard} />
                </Route>
                <Route exact path="/cardpreview">
                    <CardPreview vCard={vCard} />
                </Route>
            </Switch>
        </BrowserRouter>
    );
}

export default VeeCard;
