import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Additional from './Additional';
import { useInput } from '../../hooks/input-hook';
import { Redirect } from 'react-router-dom';


async function createWallet(body) {
    // Post data
    const response = await fetch('/createvcard', {
        method: 'POST', headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body)
    });
    const obj = await response.json();
    return obj;
}


function Main(props) {
    const [toPreview, setToPreview] = useState(false);

    const { t } = useTranslation();
    const { value: position, bind: bindPosition } = useInput('');
    const { value: firstName, bind: bindFirstName } = useInput('');
    const { value: lastName, bind: bindLastName } = useInput('');
    const { value: email, bind: bindEmail } = useInput('');
    const { bind: bindEmailUser } = useInput('');
    const { value: phone, bind: bindPhone } = useInput('');
    const { value: organization, bind: bindOrganization } = useInput('');
    const [phones, setPhoneState] = useState({});
    const setPhones = (argPhones) => {
        setPhoneState({...phones, ...argPhones});
    }
    const [address, setAddressState] = useState({});
    const setAddress = (argAddress) => {
        setAddressState({...address, ...argAddress});
    }
    const [website, setWebsiteState] = useState();
    const setWebsite = (argWebsite) => {
        setWebsiteState(argWebsite);
    }
    const [creating, setCreating] = useState(false);
    const handleSubmit = async (evt) => {
        evt.preventDefault();
        setCreating(true);
        const body = { position, firstName, lastName, email, phone, organization,
            ...phones, ...address, ...website }
        try {
            const walletData = await createWallet(body);
            body.walletData = walletData;
            props.setVCard(body);
            setToPreview(true);
        } catch {
            setCreating(false);
        }
    }

    return (
        <div>
            { toPreview && 
                <Redirect to="/cardpreview" push={true} />
            }
            <div className="row mt-4">
                <div className="col-12">
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label htmlFor="position">{t('home.position')}</label>
                            <input type="text" className="form-control form-control form-control-lg font-weight-light" id="position" placeholder={t('home.ceo')} {...bindPosition} />
                        </div>
                        <div className="form-group ">
                            <label htmlFor="firstname">{t('home.firstname')}</label>
                            <input type="text" className="form-control form-control form-control-lg font-weight-light" placeholder="John" id="firstname" required {...bindFirstName} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="lastname">{t('home.lastname')}</label>
                            <input type="text" className="form-control form-control form-control-lg font-weight-light" id="lastname" placeholder="Doe" required {...bindLastName} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="email">{t('home.email')}</label>
                            {props.emailDomain ? (
                                <div className="d-flex flex-row">
                                    <input type="text" className="form-control form-control form-control-lg font-weight-light" id="emailUser" placeholder="user" required  {...bindEmailUser} />
                                    <span className="align-self-center ml-1">@{props.emailDomain}</span>
                                </div>
                            ) : (
                                    <input type="email" className="form-control form-control form-control-lg font-weight-light" id="email" placeholder="name@organization.com" required  {...bindEmail} />
                                )}
                        </div>
                        <div className="form-group">
                            <label htmlFor="phone">{t('home.phone')}</label>
                            <input type="tel" className="form-control form-control form-control-lg font-weight-light" id="phone" placeholder="+41791234567" pattern="[\+0-9 ]*" required  {...bindPhone} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="organization">{t('home.organization')}</label>
                            <input type="text" className="form-control form-control form-control-lg font-weight-light" id="organization"
                                placeholder="Acme Corporation" {...bindOrganization} />
                        </div>

                        <Additional {...props} {...{setPhones, setAddress, setWebsite}}></Additional>

                        <button type="submit" className="btn btn-primary mt-4 mb-5 py-3 btn-block" disabled={creating} >
                            {creating && <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>}
                            {creating ? t('home.creating') : t('home.create')}
                        </button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Main;
